@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
    background-color: #fff !important;
}

body {
    
    overflow: hidden;
}

input {
    border: 1px solid #e2e8f0;
    outline: none !important;
    padding: 0.5rem 1rem;
}